import { ErrorHandler, Injectable } from '@angular/core';
import { BpConfirmationService } from 'bp/shared/services/bp-confirmation.service';

@Injectable()
export class DevelopErrorHandler implements ErrorHandler {
  constructor(private bpConfirmationService: BpConfirmationService) {}
  handleError(error: any) {
    const whiteList = ['Argumento de constructor de matriz con tipo no válido', '_avast_submit'];

    whiteList.forEach(element => {
      if (error.message?.includes(element)) {
        console.error('white-list error:', [error]);
        return;
      }
    });

    console.log(error);

    console.error('dev error handler:', error);
    this.bpConfirmationService.open(
      {
        title: 'Error no controlado: ' + error.name,
        message: error.message,
      },
      'error',
    );
    /*Swal.fire({
      iconHtml: '<i class="icon icon-swal fas fa-exclamation-triangle"></i>',
      customClass: {
        container: 'swal2-fixe',
        confirmButton: 'btn',
        cancelButton: 'btn',
      },
      buttonsStyling: false,
      showClass: {
        icon: 'swal2-noanimation',
      },
      hideClass: {
        icon: 'swal2-noanimation',
      },
      footer:
        '<div class="border"><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div></div>',
      icon: 'error',
      title: 'Error no controlado:' + error.name,
      text: error.message,
    });*/
    // LogRocket.captureException(error);
  }
}
