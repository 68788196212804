import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'bp/layout/layout.component';
import { BpAuthGuard } from 'projects/bp-core/src/lib/services/portal/is-login.guard';
import { AcceptedRiskGuard } from 'projects/bp-portal/src/bp/shared/guards/accepted-risk.guard';
// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const bpAppRoutes: Route[] = [
  // Redirect TO dashboard
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

  // NO guard - NO sidebar - NO bpstepsheader
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      // Register
      {
        path: 'register',
        loadChildren: () =>
          import('bp/modules/get-started/get-started.module').then(mod => mod.GetStartedModule),
      },

      // Auth
      {
        path: 'auth',
        loadChildren: () => import('bp/modules/auth/auth.module').then(mod => mod.AuthModule),
      },

      // Maintenance
      {
        path: 'maintenance',
        loadChildren: () =>
          import('bp/modules/maintenance/maintenance.module').then(m => m.MaintenanceModule),
      },

      // Confirmation
      {
        path: 'confirmation',
        data: {
          layout: 'bpstepsheader',
        },
        loadChildren: () =>
          import('bp/modules/confirmation/confirmation.module').then(m => m.ConfirmationModule),
      },

      // Clear
      {
        path: 'clear',
        loadChildren: () => import('bp/modules/clear/clear.module').then(m => m.ClearModule),
      },
    ],
  },

  // SÍ guard - NO sidebar - NO bpstepsheader
  {
    path: '',
    canActivate: [BpAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      // Validateci ( header propio - migrar al bp-steps )
      {
        path: 'validateci',
        loadChildren: () =>
          import('bp/modules/validate-ci/validate-ci.module').then(m => m.ValidateCiModule),
      },

      // Blog ( header propio )
      {
        path: 'blog',
        loadChildren: () => import('bp/modules/blog/blog.module').then(m => m.BlogModule),
      },
    ],
  },

  // SÍ guard - NO sidebar - SÍ bpstepsheader
  {
    path: '',
    canActivate: [BpAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'bpstepsheader',
    },
    children: [
      // Contract
      {
        path: 'contract',
        loadChildren: () =>
          import('bp/modules/contract/contract.module').then(m => m.ContractModule),
      },

      // Rescue
      {
        path: 'rescue',
        loadChildren: () => import('bp/modules/rescue/rescue.module').then(m => m.RescueModule),
      },

      // Deposit
      {
        path: 'deposit',
        loadChildren: () => import('bp/modules/deposit/deposit.module').then(m => m.DepositModule),
      },

      // Change Portfolio
      {
        path: 'change-portfolio',
        loadChildren: () =>
          import('bp/modules/change-portfolio/change-portfolio.module').then(
            m => m.ChangePortfolioModule,
          ),
      },

      // Onboarding
      {
        path: 'onboarding',
        loadChildren: () =>
          import('bp/modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
      },

      // Schedule Advisor
      {
        path: 'schedule-advisor',
        loadChildren: () =>
          import('bp/modules/schedule-advisor/schedule-advisor.module').then(
            m => m.ScheduleAdvisorModule,
          ),
      },
    ],
  },

  // SÍ guard - si sidebar - NO bpstepsheader
  {
    path: '',
    canActivate: [BpAuthGuard, AcceptedRiskGuard],
    component: LayoutComponent,
    data: {
      layout: 'bp',
    },
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      // Dashboard
      {
        path: 'dashboard',
        loadChildren: () =>
          import('bp/modules/dashboard/dashboard.module').then(m => m.BpDashboardModule),
      },

      // Referral
      {
        path: 'referral',
        loadChildren: () =>
          import('bp/modules/referral/referral.module').then(m => m.ReferralModule),
      },

      // Entity
      {
        path: 'entity',
        loadChildren: () => import('bp/modules/entity/entity.module').then(m => m.EntityModule),
      },

      // Goal
      {
        path: 'goals',
        loadChildren: () => import('bp/modules/goal/goal.module').then(m => m.GoalModule),
      },
      // {
      //   path: 'insurances',
      //   loadChildren: () => import('bp/modules/insurance/insurance.routes'),
      // },

      // Pending Transaction Notifications
      {
        path: 'pending-transaction-notifications',
        loadChildren: () =>
          import(
            'bp/modules/pending-transaction-notifications/pending-transaction-notifications.module'
          ).then(m => m.PendingTransactionNotificationsModule),
      },

      // Short Term Invest
      {
        path: 'short-term-invest',
        loadChildren: () =>
          import('bp/modules/short-term-invest/short-term-invest.module').then(
            m => m.ShortTermInvestModule,
          ),
      },

      // Short Term Invest Plus
      {
        path: 'short-term-invest-plus',
        loadChildren: () =>
          import('bp/modules/short-term-invest-plus/short-term-invest-plus.module').then(
            m => m.ShortTermInvestPlusModule,
          ),
      },

      // Short Term Invest Dollars
      {
        path: 'short-term-invest-dollars',
        loadChildren: () =>
          import('bp/modules/short-term-invest-dollars/short-term-invest-dollars.module').then(
            m => m.ShortTermInvestDollarsModule,
          ),
      },
      {
        path: 'short-term-invest-dollars-plus',
        loadChildren: () =>
          import(
            'bp/modules/short-term-invest-dollars-plus/short-term-invest-dollars-plus.module'
          ).then(m => m.ShortTermInvestDollarsPlusModule),
      },

      // Dollarbox
      {
        path: 'dollarbox',
        loadChildren: () =>
          import('bp/modules/dollarbox/dollarbox.module').then(m => m.DollarboxModule),
      },

      // Ibkr - layout mixtos
      {
        path: 'ibkr',
        loadChildren: () => import('bp/modules/ibkr/ibkr.module').then(m => m.IbkrModule),
      },

      // Advisor
      {
        path: 'advisor',
        loadChildren: () => import('bp/modules/advisor/advisor.module').then(m => m.AdvisorModule),
      },

      // Pricing
      {
        path: 'pricing',
        loadChildren: () => import('bp/modules/pricing/pricing.module').then(m => m.PricingModule),
      },

      // PROFILE
      {
        path: 'profile',
        loadChildren: () => import('bp/modules/profile/profile.module').then(m => m.ProfileModule),
      },
    ],
  },

  // En duda si debe renderizarse
  {
    path: '',
    canActivate: [BpAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      // Kyc
      {
        path: 'kyc',
        loadChildren: () => import('bp/modules/kyc/kyc.module').then(m => m.KycModule),
      },
      {
        path: 'companies',
        loadChildren: () =>
          import('bp/modules/companies/companies.module').then(m => m.CompaniesModule),
      },
    ],
  },
];

export const notMatchRedirectRoutes: Route[] = [
  {
    path: 'redirect-to',
    pathMatch: 'full',
    loadChildren: () =>
      import('bp/modules/redirect/redirect.module').then(mod => mod.RedirectModule),
  },
  { path: '**', redirectTo: 'redirect-to' },
];

export const notMatchRedirectRoutesOld: Route[] = [
  {
    path: 'not-found',
    pathMatch: 'full',
    loadChildren: () => import('bp/modules/error-404/error-404.routes'),
  },
  { path: '**', redirectTo: 'not-found' },
];

//{ path: '**', redirectTo: 'redirect-to', pathMatch: 'full' },
//{ path: '**', redirectTo: '404-not-found' },
