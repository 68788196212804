import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { AppInsightsService, INSIGHTS_CONFIG, InsightsConfig } from './app-insights.service';

@NgModule({
  providers: [
    AppInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
})
export class AppInsightsModule {
  static forRoot(config: InsightsConfig): ModuleWithProviders<AppInsightsModule> {
    return {
      ngModule: AppInsightsModule,
      providers: [
        {
          provide: INSIGHTS_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
