import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AppInsightsService } from '@bp-core/src/lib/modules/app-insights/app-insights.service';
import { AppInteractionService } from '@bp-core/src/lib/services/app-interaction.service';
import { environment } from '@environment/environment';
import * as Sentry from '@sentry/angular-ivy';
// import { Angulartics2GoogleGlobalSiteTag, Angulartics2GoogleTagManager } from 'angulartics2';
import { ComponentFactoryService } from 'bp/shared/services/component-factory.service';
import { PortalConfigParamService } from 'bp/shared/services/portal-config-param.service';
import * as LogRocket from 'logrocket';
import {
  OAuthEvent,
  OAuthService,
  OAuthSuccessEvent,
} from 'projects/bp-core/src/lib/external/angular-oauth2-oidc-jwks/public_api';
import { IConfigFirebase } from 'projects/bp-core/src/lib/models/config.firebase';
import { AuthService } from 'projects/bp-core/src/lib/services/portal/auth.service';
import { VersionCheckService } from 'projects/bp-core/src/lib/services/version-check.service';
import { WVCommunicationService } from 'projects/bp-core/src/lib/services/wvcommunication.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private authService: AuthService,
    private appInteractionService: AppInteractionService,
    // private angulartics: Angulartics2GoogleGlobalSiteTag,
    // private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private wvCommunicationService: WVCommunicationService,
    private versionCheckService: VersionCheckService,
    private firestore: AngularFirestore,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryService: ComponentFactoryService,
    private portalConfigParamService: PortalConfigParamService,
    private snackBar: MatSnackBar,
    private appInsights: AppInsightsService,

    private readonly swUpdate: SwUpdate,
  ) {
    this.appInsights.addOrUpdateTags({
      environment: environment.name,
      version: environment.version,
    });
    window.onerror = (message, source, lineno, colno, error) => {
      Sentry.captureException(error);
      console.error('error', message, source, lineno, colno, error);
    };
    this.componentFactoryService.setViewContainerRef(this.viewContainerRef);

    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        console.info('versionUpdates', evt);
        console.log('actualizando nueva version');

        const snackBarRef = this.snackBar.open(
          'Nueva versión disponible, actualizando...',
          'Cancelar',
          {
            duration: 3000,
            panelClass: ['snack-success'],
          },
        );

        snackBarRef.afterDismissed().subscribe(dis => {
          if (!dis.dismissedByAction) {
            console.log('dismissed');
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        });

        snackBarRef.onAction().subscribe(dis => {
          console.log('Cancelada la recarga');
        });
      });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      console.log('se navego a la URL:', (event as NavigationEnd).url);
      this.wvCommunicationService.setNavigationEnd(
        window.location.origin,
        (event as NavigationEnd).url,
      );
    });
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
      console.log('Intentando navegar a la URL:', (event as NavigationStart).url);
      this.wvCommunicationService.setNavigationStart(
        window.location.origin,
        (event as NavigationStart).url,
      );
    });

    // this.angulartics.startTracking();
    // this.angulartics2GoogleTagManager.startTracking();
    this.firestore
      .collection<IConfigFirebase>('config')
      .valueChanges()
      .subscribe(
        values => {
          if (
            values &&
            values[0] &&
            (values[0].maintenanceGetStarted || values[0].maintenancePortal)
          ) {
            this.router.navigate(['/maintenance']);
          }
        },
        e => {
          console.error(e);
        },
      );

    this.oauthService.configure({
      issuer: environment.auth.issuer,
      clientId: environment.auth.client_id_code,
      scope: 'openid profile email apiv1 apiv2 offline_access IdentityServerApi',
      redirectUri: window.location.origin + '/auth/login/',
      silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
      useSilentRefresh: true,
      sessionChecksEnabled: true,
      clockSkewInSec: 86400,
      // timeoutFactor: 0.01,
      clearHashAfterLogin: false,
      // sessionChecksEnabled: true,
      // tokenEndpoint: environment.auth.issuer + '/connect/token',
      // userinfoEndpoint: environment.auth.issuer + '/connect/userinfo'
      responseType: 'code',
      showDebugInformation: true,
      postLogoutRedirectUri: window.location.origin,
      logoutUrl: `${environment.auth.issuer}/Identity/Account/Logout?returnUrl=${window.location.origin}`,
    });

    this.oauthService.setStorage(localStorage);
    // this.oauthService.customTokenParameters = [];
    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(async () => {
        console.log('loadDiscoveryDocument true');
        this.authService.hasDiscoveredSubject$.next(true);
      })
      .catch(e => {
        // LogRocket.captureException(e);

        console.log('loadDiscoveryDocument false');
        this.authService.hasDiscoveredSubject$.next(false);
        localStorage.removeItem('bp-enter-as-id');
        localStorage.removeItem('bp_is_admin');
        Sentry.captureException(e);
        this.oauthService.logOut();

        // Swal.fire({
        //   iconHtml:
        //     '<i class="icon icon-swal fas fa-exclamation-triangle"></i>',
        //   customClass: {
        //     container: 'swal2-fixe',
        //     confirmButton: 'btn',
        //     cancelButton: 'btn'
        //   },
        //   buttonsStyling: false,
        //   showClass: {
        //     icon: 'swal2-noanimation'
        //   },
        //   hideClass: {
        //     icon: 'swal2-noanimation'
        //   },
        //   footer:
        //     '<div class="border"><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div></div>',
        //   icon: 'error',
        //   title: 'Error!',
        //   text:
        //     'Lo sentimos, no pudimos conectarnos con el servidor, si continua el problema contáctenos a traves del chat.'
        // });
      });
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events
      .pipe(filter(e => e.type === 'token_received'))
      .subscribe((event: OAuthEvent) => {
        if (event instanceof OAuthSuccessEvent) {
          // Redirect to url by which the application was initially called.
          const state = this.oauthService.state;

          if (state) {
            const redirectUrl = decodeURIComponent(state);
            const redirectUrlOrigin = new URL(redirectUrl).origin;
            const redirectUrlPathname = new URL(redirectUrl).pathname;
            console.info('se redirige a', redirectUrlOrigin + '/auth/login#' + redirectUrlPathname);
            window.location.href = redirectUrlOrigin + '/auth/login#' + redirectUrlPathname;
          }
        }
      });
    this.oauthService.events.pipe(filter(e => e.type === 'token_received')).subscribe(async e => {
      const accessToken = this.oauthService.getAccessToken();
      this.wvCommunicationService.setAccessToken(accessToken);
      this.wvCommunicationService.isLogin(true);
    });
    this.oauthService.events
      .pipe(filter(e => e.type === 'session_terminated' || e.type === 'token_expires'))
      .subscribe(async e => {
        this.wvCommunicationService.isLogin(false);
        console.info('Your session has been terminated!');
        if (environment.name == 'Production') {
          LogRocket.captureMessage('Your session has been terminated! ' + e.type);
          Sentry.captureMessage('Your session has been terminated! ' + e.type);
        }
        localStorage.removeItem('bp-enter-as-id');
        localStorage.removeItem('bp_is_admin');
        this.oauthService.logOut();
        // const expiration = this.oauthService.getAccessTokenExpiration();
        // const storedAt = this.getAccessTokenStoredAt();
        // const timeout = this.calcTimeout(storedAt, expiration);
        // console.log('calc1', expiration, storedAt, timeout);

        // const expirationIdToken = this.oauthService.getIdTokenExpiration();
        // const storedAtIdToken = this.getIdTokenStoredAt();
        // const timeoutIdToken = this.calcTimeout(storedAt, expiration);

        // console.log(
        //   'calc IdToken ',
        //   expirationIdToken,
        //   storedAtIdToken,
        //   timeoutIdToken
        // );
        // console.log(
        //   'getIdTokenExpiration',
        //   this.oauthService.getIdTokenExpiration(),
        //   dayjs(this.oauthService.getIdTokenExpiration()).format('L LLL')
        // );
        // console.log(
        //   'getAccessTokenExpiration',
        //   this.oauthService.getAccessTokenExpiration(),
        //   dayjs(this.oauthService.getAccessTokenExpiration()).format('L LLL')
        // );
        // this.authService.hasDiscoveredSubject$
        //   .pipe(take(1))
        //   .subscribe(async result => {
        //     if (result) {
        //       try {
        //         console.log('refreshToken');
        //         const res = await this.oauthService.refreshToken();
        //         console.log(res);
        //       } catch (error) {
        //         console.error(error);
        //         this.oauthService.initCodeFlow();
        //       }
        //     }
        //   });

        // //   this.authService.logout(true);
      });

    this.oauthService.events.pipe(filter(e => e.type !== 'session_unchanged')).subscribe(e => {
      console.log(e);
    });
  }

  calcTimeout(storedAt: number, expiration: number): number {
    const timeoutFactor = this.oauthService?.timeoutFactor ?? 1;
    const now = Date.now();
    const delta = (expiration - storedAt) * timeoutFactor - (now - storedAt);
    return Math.max(0, delta);
  }
  getAccessTokenStoredAt(): number {
    return parseInt(localStorage.getItem('access_token_stored_at') ?? '1', 10);
  }
  getIdTokenStoredAt(): number {
    return parseInt(localStorage.getItem('id_token_stored_at') ?? '1', 10);
  }
  refreshForce(): void {
    this.oauthService.silentRefresh();
  }

  ngOnInit(): void {
    console.log('VERSION:', environment.version);
    console.log('MODO:', environment.name);

    //console.log(this.versionCheckService);

    //this.versionCheckService.initVersionCheck('/assets/version.json');

    (window as any).Intercom('boot', {
      app_id: environment.keyIntercom,
    });
    this.router.events.subscribe(event => {
      // if (event instanceof NavigationStart) {
      //   // Show loading indicator
      // }

      if (event instanceof NavigationEnd) {
        (window as any).Intercom('update');
        // Hide loading indicator
        // console.log(event);
      }

      // if (event instanceof NavigationError) {
      //   // Hide loading indicator

      //   // Present error to user
      //   console.log(event.error);
      // }
    });
  }
}
